import { when, reaction } from 'mobx';
import { types, getParent, getSnapshot, applySnapshot, destroy } from 'mobx-state-tree';
import cuid from 'cuid';
import dayjs from 'dayjs';

import DateTime from './date';
import { SearchLog } from './SearchLogs';
import { Restaurant } from './Restaurants';

export const UserSearch = types
  .model("UserSearch", {
    id: types.optional(types.identifier, () => cuid()),
    uid: types.optional(types.maybeNull(types.string), null),
    folderid: types.optional(types.maybeNull(types.string), null),
    userid: types.optional(types.maybeNull(types.string), null),
    restaurant: types.optional(types.maybeNull(types.string), null),
    restaurantdata: types.optional(Restaurant, {}),
    created: types.optional(DateTime, null),
    date: types.optional(DateTime, () => dayjs().add(2, 'hours').valueOf()),
    partysize:	types.optional(types.number, 1),
    enabled:	types.optional(types.boolean, true),
    deleted:	types.optional(types.boolean, false),
    lastemailnotification: types.optional(DateTime, null),
    lastsmsnotification: types.optional(DateTime, null),
    createdat: types.optional(DateTime, null),
    updatedat: types.optional(DateTime, null),
    deletedat: types.optional(DateTime, null),
    secondary: types.maybeNull(types.string),
    enableemail: types.optional(types.boolean, true),
    enablesms:	types.optional(types.boolean, true),
    logs: types.optional(types.array(SearchLog), []),
  })
  .actions(self => ({
    setProp(prop, value) {
      self[prop] = value;
    },
    update(props) {
      const snap = getSnapshot(self);
      const record = Object.assign(
        {},
        snap,
        props
      );
      applySnapshot(self, record);
    },
    delete() {
      self.deletedat = dayjs().valueOf();
    },
    remove() {
      getParent(self, 2).remove(self);
    },
    addLog(log) {
      self.logs.unshift(log);
    }
  })
);

export const UserSearches = types
  .model("UserSearches", {
    records: types.array(UserSearch),
  })
  .actions(self => ({
    add(record) {
      let r = self.records.find(r => r.id === record.id);
      if (!r) {
        self.records.push(record);
        r = self.records[self.records.length - 1];
      }
      return r;
    },
    update(record) {
      let r = self.records.find(r => r.id === record.id);
      if (r) {
        r.remove();
      }
      self.records.push(record);
      return record;
    },
    remove(record) {
      destroy(record);
    },
    clear() {
      self.records.clear();
    },
  })
);