import { when, reaction } from 'mobx';
import { types, getParent, getSnapshot, applySnapshot, destroy } from 'mobx-state-tree';
import cuid from 'cuid';
import dayjs from 'dayjs'

import DateTime from './date';

export const User = types
  .model("User", {
    id: types.optional(types.identifier, () => cuid()),
    email: types.optional(types.maybeNull(types.string), null),
    password: types.optional(types.maybeNull(types.string), null),
    firstname: types.optional(types.maybeNull(types.string), null),
    lastname: types.optional(types.maybeNull(types.string), null),
    displayname: types.optional(types.maybeNull(types.string), null),
    zipcode: types.optional(types.maybeNull(types.string), null),
    phone: types.optional(types.maybeNull(types.string), null),
    carrier: types.optional(types.maybeNull(types.string), null),
    sendtxt:	types.optional(types.boolean, false),
    sendemail:	types.optional(types.boolean, false),
    emailtimeout:	types.optional(types.number, 14400),
    smstimeout:	types.optional(types.number, 14400),
    activated:	types.optional(types.boolean, false),
    admin:	types.optional(types.boolean, false),
    subexpires: types.optional(DateTime, null),
    eula: types.optional(DateTime, null),
    createdat: types.optional(DateTime, null),
    updatedat: types.optional(DateTime, null),
    deletedat: types.optional(DateTime, null),
  })
  .actions(self => ({
    setProp(prop, value) {
      self[prop] = value;
    },
    update(props) {
      const snap = getSnapshot(self);
      const record = Object.assign(
        {},
        snap,
        props
      );
      applySnapshot(self, record);
    },
    delete() {
      self.deletedat = dayjs().valueOf();
    },
    remove() {
      getParent(self, 2).remove(self);
    }
  })
);

export const Users = types
  .model("Users", {
    records: types.array(User),
  })
  .actions(self => ({
    add(record) {
      let r = self.records.find(r => r.id === record.id);
      if (!r) {
        self.records.push(record);
        r = self.records[self.records.length - 1];
      }
      return r;
    },
    remove(record) {
      destroy(record);
    },
    clear() {
      self.records.clear();
    },
  })
);