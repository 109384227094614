import { types } from "mobx-state-tree";
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(utcPlugin);

const DateTime = types.custom({
  name: "DateTime",
  fromSnapshot: string => string && string !== "Invalid date" ? dayjs.utc(string) : null,
  toSnapshot: (mDate) => mDate ? mDate.format('YYYY-MM-DD HH:mm:ss') : null,
  isTargetType: maybeMoment => dayjs.isDayjs(maybeMoment),
  getValidationMessage: snapshot => {
    if (snapshot === undefined || snapshot === null) return "";
    const mDate = dayjs((/^\d+$/.test(snapshot)) ? parseInt(snapshot) : snapshot);
    if (!mDate.isValid()) {
      const message = `"${snapshot}" is not in valid date format`;
      console.error(message);
      return message;
    }
    return "";
  }
});

export default DateTime;