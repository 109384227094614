import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStores } from '../hooks/use-stores';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const store = useStores();
  return (
    <Route
      {...rest}
      render={props =>
        store.activeUser && store.activeUser.uid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
