import React, { Component, useState, Fragment } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  IonItem,
  IonItemDivider,
  IonLabel,
  IonButton,
  IonInput,
  IonToast,
  IonText,
  IonNote,
} from "@ionic/react";
import { observer } from 'mobx-react-lite';

import { useStores } from '../hooks/use-stores';
import { ERRORS, ERROR_MSGS } from '../store/constants';
import BasicPage from "../components/BasicPage";

/**
 * sets the `title` and property hasMenu = true so that the menu for the side
 * drawer is displayed
 *
 * sets the `renderContent` propety to render the contents of the page
 */
//const LoginPage = ({ isAuth, doLogin }) => {

const LoginPage = () => {
  const history = useHistory();
  const store = useStores();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showEmailLogin, setEmailLogin] = useState(false);
  const [showErrorToast, setErrorToast] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const { isAuth, initializationError, activeUser } = store;

  const loginGoogle = async (e) => {
    e.preventDefault();
    const user = await store.socialSignIn('google');
    return user;
  };

  const loginFacebook = async (e) => {
    e.preventDefault();
    const user = await store.socialSignIn('facebook');
    return user;
  };

  const loginEmail = async (e) => {
    e.preventDefault();
    const result = await store.emailLogin(email, password);
    return result;
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    history.push('/forgotPassword');
  };

  if (activeUser && activeUser.uid) {
    return <Redirect to="/home" />;
  } else {
    return (
      <Fragment>
        <IonText color="danger" padding style={{ fontWeight: "500" }}>
          {initializationError && initializationError.message}
        </IonText>
        <BasicPage
          title="WDW Tables"
          hasMenu
          renderContent={history => {
            return (
              <Fragment>
                {showEmailLogin ? 
                  <Fragment>
                    <IonItem>
                      <IonLabel position="floating">Email Address</IonLabel>
                      <IonInput
                        color={store.loginError.error && store.loginError.code === ERRORS.ERROR_CODE_NOT_FOUND  ? "danger" : "dark"}
                        type="email"
                        name="email"
                        onIonChange={e => setEmail(e.detail.value)}
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="floating">Password</IonLabel>
                      <IonInput
                        color={store.loginError.error && store.loginError.code === ERRORS.ERROR_CODE_WRONG_PASSWORD  ? "danger" : "dark"}
                        type="password"
                        name="password"
                        onIonChange={e => setPassword(e.detail.value)}
                      />
                    </IonItem>
                    <div style={{ padding: 10, paddingTop: 20 }}>
                      <IonButton
                        disabled={!(password && email)}
                        expand="full"
                        style={{ margin: 14 }}
                        onClick={loginEmail}
                      >
                        {isAuth ? "Logged In" : "Login"}
                      </IonButton>
                      <IonButton
                        color="tertiary"
                        expand="full"
                        style={{ margin: 14 }}
                        onClick={e => setEmailLogin(false)}
                      >
                        Cancel
                      </IonButton>
                      <IonButton
                        color="secondary"
                        expand="full"
                        fill="clear"
                        style={{ margin: 14 }}
                        onClick={e => {
                          e.preventDefault();
                          history.push("/register");
                        }}
                      >
                        Don't have an account? Create one!
                      </IonButton>
                      <IonButton
                        color="dark"
                        expand="full"
                        fill="clear"
                        style={{ margin: 14 }}
                        onClick={resetPassword}
                      >
                        Reset password
                      </IonButton>
                    </div>
                  </Fragment> :
                  <Fragment>
                    <IonButton
                      color="tertiary"
                      expand="full"
                      style={{ margin: 14 }}
                      onClick={loginGoogle}
                    >
                      Login with Google
                    </IonButton>
                    <IonButton
                      expand="full"
                      style={{ margin: 14 }}
                      onClick={loginFacebook}
                    >
                      Login with Facebook
                    </IonButton>
                    <IonItemDivider />
                    <IonButton
                      color="secondary"
                      expand="full"
                      style={{ margin: 14 }}
                      onClick={e =>  setEmailLogin(true)}
                    >
                      Login with Email
                    </IonButton>
                    <IonButton
                      color="dark"
                      expand="full"
                      fill="clear"
                      style={{ margin: 14 }}
                      onClick={e => {
                        e.preventDefault();
                        history.push("/register");
                      }}
                    >
                      Don't have an account? Create one!
                    </IonButton>
                  </Fragment>
                }
              </Fragment>
            );
          }}
        />
        <IonToast
          color="danger"
          isOpen={store.loginError.error}
          showCloseButton
          onDidDismiss={() => store.clearLoginError()}
          message={store.loginError.message}
        />
      </Fragment>
    );
  }
}

export default observer(LoginPage);