import { when, reaction } from 'mobx';
import { types, getParent, getSnapshot, applySnapshot, destroy } from 'mobx-state-tree';
import cuid from 'cuid';
import dayjs from 'dayjs'

import DateTime from './date';

export const Restaurant = types
  .model("Restaurant", {
    id: types.optional(types.maybeNull(types.string), null),
    name: types.optional(types.maybeNull(types.string), null),
    createdat: types.optional(DateTime, null),
    updatedat: types.optional(DateTime, null),
    deletedat: types.optional(DateTime, null),
    secondary: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setProp(prop, value) {
      self[prop] = value;
    },
    update(props) {
      const snap = getSnapshot(self);
      const record = Object.assign(
        {},
        snap,
        props
      );
      applySnapshot(self, record);
    },
    delete() {
      self.deletedat = dayjs().valueOf();
    },
    remove() {
      getParent(self, 2).remove(self);
    }
  })
);

export const Restaurants = types
  .model("Restaurants", {
    records: types.array(Restaurant),
  })
  .actions(self => ({
    add(record) {
      let r = self.records.find(r => r.id === record.id);
      if (!r) {
        self.records.push(record);
        r = self.records[self.records.length - 1];
      }
      return r;
    },
    update(record) {
      let r = self.records.find(r => r.id === record.id);
      if (r) {
        r.remove();
      }
      self.records.push(record);
      return record;
    },
    remove(record) {
      destroy(record);
    },
    clear() {
      self.records.clear();
    },
  })
);