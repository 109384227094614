import React, { useCallback, useState, useEffect } from 'react';
import { css } from 'styled-components';
import {
  IonItem,
  IonContent,
  IonLabel,
  IonHeader,
  IonRange,
  IonModal,
  IonTitle,
  IonPage,
  IonIcon,
  IonButton,
  IonButtons,
  IonBackButton,
  IonToolbar,
  IonToast,
  IonText,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonAvatar,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { isPlatform } from '@ionic/react';
import { checkmarkCircle, closeCircle, removeCircle, add, trash, card } from 'ionicons/icons';
import { PaymentSheetEventsEnum } from '@capacitor-community/stripe';
import images from 'react-payment-inputs/images';
import { useHistory } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import spacetime from 'spacetime';
import TimeAgo from 'react-timeago';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useStores } from '../hooks/use-stores';
import { useCapacitorStripe } from '../components/Provider';
import WebPayment from '../components/WebPayment';
import settings from '../config';

const stripePromise = loadStripe(settings.stripe.publishableKey);
dayjs.extend(utcPlugin);

const styles = {
  card: {
    paddingTop: 8,
    paddingBottom: 8,
  }
};
const Payment = () => {
  const history = useHistory();
  const store = useStores();
  const { stripe } = useCapacitorStripe();
  const [showLoading, setShowLoading] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [webPayReady, setWebPayReady] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Payment failed');

  const showPaymentModal = async () => {
    if (isPlatform('capacitor')) {
      showMobilePayment();
    } else {
      showWebPayment();
    }
  };
  const showWebPayment = async () => {
    setShowLoading(true);
    setErrorMessage('Payment failed');
    try {
      await store.updatePaymentToken(store.payment.amount);
      setModalOpen(true);
      setShowLoading(false);
    } catch (e) {
      console.log(e);
      setErrorMessage(e.message);
      setShowErrorToast(true);
      history.push('/tab/subscriptions');
    }
  };
  const showMobilePayment = async () => {
    try {
      await store.updatePaymentToken(store.payment.amount);
      const paymentSheet = await stripe.createPaymentSheet({
        paymentIntentClientSecret: store.paymentToken.client_secret,
        customerId: store.customerToken.id,
        customerEphemeralKeySecret: store.ephemeralKey,
      });

      // present PaymentSheet and get result.
      const result = await stripe.presentPaymentSheet();
      if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
        console.log(result);
        setShowLoading(true);
        const payment = await store.getPaymentIntent(store.paymentToken.id);
        await store.savePayment({ paymentIntent: payment });
        setShowLoading(false);
        history.push('/tab/subscriptions');
      } else {
        throw Error('Payment failed');
      }
    } catch (e) {
      console.log(e);
      setErrorMessage(e.message);
      setShowErrorToast(true);
      history.push('/tab/subscriptions');
    }
  }

  const cancel = () => {
    store.cancelSubscriptionPurchase();
    history.push('/tab/subscriptions');
  };

  const handleBlur = () => {
    console.log('[blur]');
  };
  const handleChange = (change) => {
    console.log('[change]', change);
    const ready = (change.complete) ? true : false;
    setWebPayReady(ready);
  };
  const handleClick = () => {
    console.log('[click]');
  };
  const handleFocus = () => {
    console.log('[focus]');
  };
  const handleReady = () => {
    console.log('[ready]');
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              onClick={() => cancel()}
              defaultHref="/tab/subscriptions"
            />
          </IonButtons>
          <IonTitle>Add Subscription</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScrollStart={() => {}}
        onIonScroll={() => {}}
        onIonScrollEnd={() => {}}
      >
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Payment processing...'}
        />
        {store.payment ?
          <React.Fragment>
            {store.currentSubscription ?
              <IonItem
                href="/subscriptions/add"
                color="warning"
              >
                <IonAvatar slot="start">
                  <IonIcon icon={card} size="large" style={{ marginTop: 5 }} />
                </IonAvatar>
                <IonLabel color="dark">
                  A current valid subscription already exists.
                </IonLabel>
              </IonItem> : null
            }
            {!store.currentSubscription ?
              <IonItem lines="full">
                <IonLabel position="stacked">Plan</IonLabel>
                <IonSelect
                  value={store.payment.type}
                  okText="Select"
                  cancelText="Dismiss"
                  onIonChange={e => store.updateSubscriptionProp('type', e)}
                >
                  <IonSelectOption value="standard">Standard (6 months) - 8 Anytime Searches</IonSelectOption>
                </IonSelect>
              </IonItem> : null
            }
            <IonItem lines="full">
              <IonLabel position="stacked">Additional Anytime Searches</IonLabel>
              <IonRange
                min={0}
                max={30}
                step={3}
                snaps
                pin
                color="secondary"
                value={store.extrasearches.numberofsearches}
                onIonChange={e => store.updateSubscriptionProp('extrasearches', e)}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel slot="start" class="ion-text-wrap">
                <IonText>
                  <h2>Total Searches</h2>
                </IonText>
              </IonLabel>
              <IonLabel slot="end" >
                {store.totalPurchaseSearches}
              </IonLabel>
            </IonItem>
            <IonItem lines="full">
              <IonLabel slot="start" class="ion-text-wrap">
                <IonText>
                  <h2>Total Cost</h2>
                </IonText>
              </IonLabel>
              <IonLabel slot="end">
                ${store.payment.amount}
              </IonLabel>
            </IonItem>
          </React.Fragment> : null
        }
        <IonToast
          isOpen={showErrorToast}
          onDidDismiss={() => setShowErrorToast(false)}
          message={errorMessage}
          duration={5000}
          color="danger"
          position="top"
        />
      </IonContent>
      <IonModal
        isOpen={modalOpen}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.5, 1]}
        onDidDismiss={() => setModalOpen(false)}
      >
        {store.paymentToken ?
          <Elements stripe={stripePromise} options={{ clientSecret: store.paymentToken.client_secret }}>
            <WebPayment
              show={setModalOpen}
              setErrorMessage={setErrorMessage}
              showErrorToast={setShowErrorToast}
              showLoading={setShowLoading}
            />
          </Elements>:
          null
        }
      </IonModal>
      <IonButton
        disabled={!store.payment || store.payment.amount === '0.00'}
        expand="full"
        color="primary"
        onClick={showPaymentModal}
      >
        Pay
      </IonButton>
    </IonPage>
  );

};

export default observer(Payment);