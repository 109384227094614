import { useEffect } from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonSpinner,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from '@ionic/react-router';
import { observer } from 'mobx-react-lite';

import { useStores } from './hooks/use-stores';
import StoreContext from './StoreContext';
import createBrowserHistory from './history';
import TabContainer from './components/TabContainer';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Edit from './pages/Edit';
import Payment from './pages/Payment';
import CreateAccount from './pages/CreateAccount';
import ForgotPassword from './pages/ForgotPassword';
import { CapacitorStripeProvider } from './components/Provider';
import settings from './config';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/base.css';

setupIonicReact();

const styles = {
  spinner: {
    position: "absolute" as 'absolute',
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
};

const _Edit = () => {
  const { id } = useParams<{ id: string }>();
  const store = useStores();
  store.cloneRecord('usersearches', id);
  return <Edit />;
};

const _Add = () => {
  const store = useStores();
  store.newSearch();

  return <Edit />;
};

const SubAdd = () => {
  const store = useStores();
  store.newPayment();
  store.getPaymentToken('0.50');
  return <Payment />;
};

const App: React.FC = () => {
  const store = useStores();
  return (
    <CapacitorStripeProvider
      publishableKey={settings.stripe.publishableKey}
      fallback={<p>Loading...</p>}
    >
      <IonApp>
        {store.loading ?
          <div style={styles.spinner}>
            <IonSpinner name="circles" />
          </div> :
          <IonReactRouter history={createBrowserHistory}>
            <Switch>
              <Redirect exact from="/" to="home" />
              <Route path="/login" component={Login} />
              <Route path="/register" component={CreateAccount} />
              <Route path="/forgotPassword" component={ForgotPassword} />
              <Route path="/edit/:id" component={_Edit} />
              <Route path="/add" component={_Add} />
              <Route path="/subscriptions/add" component={SubAdd} />
              <IonRouterOutlet>
                <PrivateRoute path="/*" component={TabContainer} />
              </IonRouterOutlet>
            </Switch>
          </IonReactRouter>
        }
      </IonApp>
    </CapacitorStripeProvider>
  );
};

export default observer(App);
