import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  //signInAndRetrieveDataWithCredential,
  GoogleAuthProvider,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail
} from "firebase/auth";

import firebaseConfig from '../config/firebase';

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export default app;

/**
 * so this function is called when the authentication state changes
 * in the application, a side effect of that is that we need to get
 * the rest of the user data from the user collection, that is 
 * done with the _handleAuthedUser callback
 */
export const authCheck = async (_handleAuthedUser) => {
  return new Promise(resolve => {
    // Listen for authentication state to change.
    onAuthStateChanged(auth, async (user) => {
      if (user != null) {
        console.log("We are authenticated now!");

        return resolve(await _handleAuthedUser(user));
      } else {
        console.log("We did not authenticate.");
        _handleAuthedUser(null);
        return resolve(null);
      }
    });
  });
};

/**
 *
 * @param {*} email
 * @param {*} password
 */
export const loginWithEmail = (email, password) => {
  return signInWithEmailAndPassword(email, password);
};

/**
 *
 * @param {*} email
 */
export const resetPassword = (email) => {
  return sendPasswordResetEmail(email);
};


/**
 *
 */
export const getCurrentUser = () => {
  return auth.currentUser;
};

/**
 *
 */
export const logOut = () => {
  return signOut();
};

/**
 *
 * @param {*} email
 * @param {*} password
 */
export const registerUser = (email, password) => {
  console.log("in registerUser");
  return createUserWithEmailAndPassword(auth, email, password);
};

/**
 *
 */
export const getUserProfile = () => {
  let user = auth.currentUser;
  console.log(user);

  return user;
};

export const signInWithPopUp = provider => signInWithPopup(auth, provider);

//export const signInAndRetrieveData = credential => signInAndRetrieveDataWithCredential(credential);

export const googleAuth = token => GoogleAuthProvider.credentialFromResult(token);

export const facebookAuth = token => FacebookAuthProvider.credentialFromResult(token);

export const signInMethods = email => fetchSignInMethodsForEmail(auth, email);

export const googleAuthProvider = () => new GoogleAuthProvider();

export const facebookAuthProvider = () => new FacebookAuthProvider();
