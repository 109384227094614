import React, { useState } from 'react';
import {
  IonButton,
  IonItem,
  IonHeader,
  IonContent,
  IonLabel,
  IonInput,
  IonPage,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import { observer } from 'mobx-react-lite';
import { useHistory } from "react-router-dom";

import { useStores } from '../hooks/use-stores';

const TabProfile = () => {
  const history = useHistory();
  const store = useStores();
  const save = () => {
    history.push('/tab/home');
    store.updateRecord('user');
  };
  const logout = async () => {
    await store.doLogout();
    history.push('/login');
  };
  return(
    <IonPage>
      <IonHeader>
        {store.deviceInfo.platform === 'web' ?
          <IonToolbar color="primary">
            <IonTitle size="large">WDW Tables</IonTitle>
          </IonToolbar> : null
        } 
      </IonHeader>
      {store.cloned ?
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
        >
          <IonItem>
            <IonLabel position="floating">Display Name</IonLabel>
            <IonInput
              value={store.cloned.displayname}
              onIonChange={e => store.updateRecordProperty('displayname', e)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Email Address</IonLabel>
            <IonInput
              value={store.cloned.email}
              onIonChange={e => store.updateRecordProperty('email', e)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Phone Number</IonLabel>
            <IonInput
              value={store.cloned.phone}
              onIonChange={e => store.updateRecordProperty('phone', e)}
            />
          </IonItem>
          <IonButton
            expand="full"
            color="primary"
            onClick={save}
          >
            Save
          </IonButton>
          <IonButton
            expand="full"
            color="danger"
            onClick={logout}
          >
            Logout
          </IonButton>
        </IonContent> :
        null
      }
    </IonPage>
  );
};

export default observer(TabProfile);