import { observable, computed, action, runInAction, get, set, entries, makeAutoObservable, autorun, extendObservable } from "mobx";
import { applySnapshot, onSnapshot, getSnapshot, onPatch, clone } from 'mobx-state-tree';
import axios from 'axios';
import AxiosOffline from 'axios-offline';
import { setIntervalAsync } from 'set-interval-async/dynamic'
import { isPlatform, getPlatforms } from '@ionic/react';
import spacetime from 'spacetime';
import dayjs from "dayjs";
import RobustWebSocket from 'robust-websocket'
import webNotification from 'simple-web-notification';
import { AsYouType } from 'libphonenumber-js';
import { FirebaseAuthentication } from '@robingenz/capacitor-firebase-authentication';
import { SplashScreen } from '@capacitor/splash-screen';
import { Storage } from '@capacitor/storage';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';
import utcPlugin from 'dayjs/plugin/utc';
import { Stripe as StripeNative, PaymentSheetEventsEnum } from '@capacitor-community/stripe';

import history from '../history';
import settings from '../config';
import { ERRORS, ERROR_MSGS } from './constants';
import * as firebaseService from "./firebaseService";
import Db from './Db';
import { DeviceToken } from './models/DeviceTokens';
import { Restaurant } from './models/Restaurants';
import { UserSearch } from './models/UserSearches';
import { Payment } from './models/Payments';
import { ExtraSearch, ExtraSearches } from './models/ExtraSearches';
import { Subscription } from './models/Subscriptions';
import { Setting } from './models/Settings';
import { SearchLog } from "./models/SearchLogs";
import wakeEvent from "../lib/wake-event";
import restaurants from './restaurants';

dayjs.extend(utcPlugin);
const url = `${settings.serverUrl}/api`;
const platforms = getPlatforms();

App.addListener('backButton', () => {
    console.log(history);
    history.goBack();
});

export default class Store {
  cloned = null;
  payment = null;
  extrasearches = null;
  subscription = null;
  card = null;
  activeUser = null;
  loading = true;
  authCheckComplete = false;
  items = new Map();
  initializationError = null;
  request = null;
  messagingToken = null;
  deviceName = null;
  deviceId = null;
  authToken = null;
  authTokenRetrieved = null;
  deviceInfo = null;
  searchFilter = null;
  loginError = {
    error: false,
    message: null,
    code: null,
  };
  pendingCred = null;
  ws = null;
  wentOffline = null;
  wentOnline = null;
  paymentToken = null;
  customerToken = null;
  ephemeralKey = null;
  timer = null;
  notificationChime = null;
  constructor() {
    makeAutoObservable(
      this, {
        request: false,

        authenticatedUser: computed,
        doCheckAuth: computed,
        itemEntries: computed,
        totalPurchaseSearches: computed,
        totalsearches: computed,
        availableSearches: computed,
        activesearches: computed,
        currentSubscription: computed,
        stripeKey: computed,

        // ACTIONS
        initializeDb: action,
        doCreateUser: action,
        doLogin: action,
        doLogout: action,
        createAccount: action,
        socialSignIn: action,
        itemByKey: action,
        cloneRecord: action,
        updateRecord: action,
        cancelSubscriptionPurchase: action,
        openTimeUrl: action,
        setLastUpdate: action,
        wsMessage: action,
        updateSearch: action,
        updateSearchResults: action,
        setLoginError: action,
        clearLoginError: action,
        emailLogin: action,
        resetPassword: action,
        setupRequest: action,
        getDeviceInfo: action,
        getPaymentToken: action,
        savePayment: action,
      },
    );
    const self = this;
    this.getDeviceInfo();
    const db = Db.create({
      restaurants: {records: restaurants},
    });
    window.addEventListener('online', (e) => { this.handleConnectionChange(e) });
    window.addEventListener('offline',  (e) => { this.handleConnectionChange(e) });
    App.addListener('appStateChange', (state) => {
      // state.isActive contains the active state
      console.log('App state changed. Is active?', state.isActive);
    });

    this.db = db;

    autorun(() => {
      if (this.activeUser && this.activeUser.uid) {
        console.log("Signed in!")
        this.initializeApp();
      } else {
        console.log("Not signed in")
      }
    })
    /*
    onPatch(this.db, (data) => {
      console.log('patch', data);
    });
    */
    if (isPlatform("capacitor")) {
      FirebaseAuthentication
        .getCurrentUser()
        .then(this.handleAuthedUser)
        .catch((e) => {
        console.log('authCheck error', e);
        this.setLoading = false;
        SplashScreen.hide();
      });
    } else {
      firebaseService.authCheck(this.handleAuthedUser).catch((e) => {
        console.log('authCheck error', e);
        this.setLoading = false;
        SplashScreen.hide();
      });
    }
  }

  set setLoading(value) {
    this.loading = value;
  }

  set paymentTokenInfo(value) {
    this.paymentToken = value;
  }

  set customerTokenInfo(value) {
    this.customerToken = value;
  }

  set ephemeralKeyInfo(value) {
    this.ephemeralKey = value;
  }

  set activeUserInfo(value) {
    this.activeUser = value;
  }

  setupRefreshTokens() {
    this.timer = setIntervalAsync(
      async () => {
        console.log('refreshing auth token');
        await this.getAuthToken();
      },
      2400000,
    );
  }

  async updateData() {
    try {
      await this.getAuthToken();
      const { data } = await this.getUserSearches();
      data.forEach(search => {
        this.updateSearch(search);
      });
    } catch (e) {
      console.log(e);
    }
  }

  async handleConnectionChange(event) {
    try {
      if (event.type === "offline") {
        console.log("You lost connection.");
        this.wentOffline = new Date(event.timeStamp);
      }
      if (event.type ==="online") {
        console.log("You are now back online.");
        this.wentOnline = new Date(event.timeStamp);
        console.log(`You were offline for ${(this.wentOnline - this.wentOffline) / 1000} seconds.`);
        setTimeout(this.updateData, 3000);
      }
    } catch (e) {
      console.log('handleConnectionChange', e);
    }
  }


  /**
   * if we have an authenticated user then get all of the profile
   * information from the database and associate it with the active
   * user
   * @param {*} _authUser
   */
  handleAuthedUser = async _authUser => {
    let retVal;
    if (_authUser) {
      let userAcctInfo = isPlatform("capacitor") ? await FirebaseAuthentication.getCurrentUser() : await firebaseService.getUserProfile();
      console.log("setting active user");
      console.log('_authUser', JSON.stringify(_authUser, null, 2));
      console.log('userAccountInfo', JSON.stringify(userAcctInfo, null, 2));
      this.activeUserInfo = ('user' in  _authUser) ? _authUser.user : _authUser;
      console.log('activeUser', JSON.stringify(this.activeUse, null, 2));
      retVal = this.activeUser;

      this.setLoading = false;
      SplashScreen.hide();
    }
    return retVal;
  };

  async initializeApp() {
    console.log('initialize application')
    try {
      await this.getAuthToken();
      this.setupRequest();
      await this.initializeStore()
      await this.initializeDb();
      this.setupRefreshTokens();
      /*
      wakeEvent(() => {
        console.log('wake event');
        this.updateData();
      });
      */
    } catch (e) {
      console.log('handleAuthedUser', e);
    }
  }

  /**
   * check to see if we have a user before starting up
   */
  async initializeStore() {
    let retVal;
    try {
      // await Storage.clear();
      const snapshot = await Storage.get({ key: 'wdwtables' });
      if (snapshot.value) {
        const vals = JSON.parse(snapshot.value);
        this.db.loadData(vals);
        console.log('wdwtables store has been hydrated');
      }
    } catch (e) {
      console.log('initializeStorage', e);
      retVal = runInAction(() => {
        this.initializationError = e;
      });
    }
    return retVal;
  }

  get doCheckAuth() {
    if (firebaseService.getCurrentUser()) {
      return this.activeUser;
    } else {
      return null;
    }
  }
  /**
   * here we check to see if ionic saved a user for us
   */
  get authenticatedUser() {
    return this.activeUser || null;
  }

  /**
   * gets all of the items as an array from the map
   */
  get itemEntries() {
    return entries(this.items);
  }

  /**
   * get a specific item based on its key
   * @param {*} _key
   */
  itemByKey(_key) {
    return get(this.items, _key);
  }

  /**
   * login using a username and password
   */
  doLogin(_username, _password) {
    debugger;
    if (_username.length) {
      return firebaseService
        .loginWithEmail(_username, _password)
        .then(
          _result => {
            return true;
          },
          err => {
            console.log(err);
            return err;
          }
        )
        .catch(e => {
          console.log('doLogin', e);
          return e;
        });
    }
  }

  /**
   * create the user with the information and set the user object
   */
  async doCreateUser(_params) {
    try {
      let newUser = await firebaseService.registerUser({
        email: _params.email,
        password: _params.password,
        firstName: _params.firstName,
        lastName: _params.lastName
      });
      return newUser;
    } catch (err) {
      debugger;
      console.log('doCreateUser', err);
      return err;
      // for (let e of err.details) {
      //   if (e === "conflict_email") {
      //     alert("Email already exists.");
      //   } else {
      //     // handle other errors
      //   }
      // }
    }
  }

  /**
   * logout and remove the user...
   */
  doLogout() {
    this.activeUserInfo = null;
    return firebaseService.logOut();
  }

  checkError(code) {
    let retVal = false;
    if (Object.values(ERRORS).indexOf(code) >= 0) {
      retVal = true;
    }
    return retVal;
  }

  async nativeGetUser() {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result.user;
  }

  async nativeSignIn(type) {
    let user;
    if (type === 'google') {
      await FirebaseAuthentication.signInWithGoogle();
      user = await this.nativeGetUser();
    } else if (type === 'facebook') {
      await FirebaseAuthentication.signInWithFacebook();
      user = await this.nativeGetUser();
    }
    return user;
  }

  async nativeSignOut() {
    await FirebaseAuthentication.signOut();
    this.doLogout();
  }

  async socialSignIn(type) {
    const self = this;
    let retVal;
    let credential;
    let link;
    let socialUser;
    let provider;

    try {
      if (isPlatform("capacitor")) {
        socialUser = await this.nativeSignIn(type);
        //const idToken = await FirebaseAuthentication.getIdToken();
        await this.handleAuthedUser(socialUser);
        /*
        if (type === 'google') {
          credential = firebaseService.googleAuth(idToken);
        } else if (type === 'facebook') {
          credential = firebaseService.facebookAuth(idToken);
        }
        
        if (this.pendingCred) {
          link = await credential.user.linkWithCredential(this.pendingCred);
          this.pendingCred = null;
        }
        */
        //self.authToken = await FirebaseAuthentication.getIdToken();
        //self.authTokenRetrieved = dayjs();
        //fireUser = await firebaseService.signInAndRetrieveData(credential);
      } else {
        if (type === 'google') {
          provider = firebaseService.googleAuthProvider();
        } else if (type === 'facebook') {
          provider = firebaseService.facebookAuthProvider();
        }
        credential = await firebaseService.signInWithPopUp(provider);
        if (this.pendingCred) {
          link = await credential.user.linkWithCredential(this.pendingCred);
          this.pendingCred = null;
        }
        //fireUser = await firebaseService.signInAndRetrieveData(credential);
      }
      retVal = true;
    } catch(e) {
      if (this.checkError(e.code)) {
        this.pendingCred = e.credential;
        // Get sign-in methods for this email.
        const methods = await firebaseService.signInMethods(e.email);
        console.log(methods);
        retVal = {
          success: false,
          payload: {
            error: e.code,
            methods,
          }
        };
        if (e.code === ERRORS.ERROR_CODE_ACCOUNT_EXISTS || e.code === ERRORS.ERROR_CODE_ALREADY_IN_USE) {
          this.setLoginError(ERROR_MSGS.ERROR_MSG_ACCOUNT_EXISTS, e.code);
        } else if (e.code === ERRORS.ERROR_CODE_INVALID_EMAIL) {
          this.setLoginError(ERROR_MSGS.ERROR_MSG_INVALID_EMAIL, e.code);
        } else if (e.code === ERRORS.ERROR_CODE_WRONG_PASSWORD) {
          this.setLoginError(ERROR_MSGS.ERROR_MSG_WRONG_PASSWORD, e.code);
        } else if (e.code === ERRORS.ERROR_CODE_NOT_FOUND) {
          this.setLoginError(ERROR_MSGS.ERROR_MSG_NOT_FOUND, e.code);
        }
      }
    }
    return retVal;
  }

  async emailLogin(email, password) {
    let credential;
    let retVal;
    try {
      credential = await firebaseService.loginWithEmail(email, password);
      if (this.pendingCred) {
        const link = await credential.user.linkAndRetrieveDataWithCredential(this.pendingCred);
        this.pendingCred = null;
      }
      retVal = true;
    } catch (e) {
      if (this.checkError(e.code)) {
        this.pendingCred = e.credential;
        // Get sign-in methods for this email.
        const methods = await firebaseService.signInMethods(email);
        console.log(methods);
        retVal = {
          success: false,
          payload: {
            error: e.code,
            methods,
          }
        };
        if (e.code === ERRORS.ERROR_CODE_ACCOUNT_EXISTS || e.code === ERRORS.ERROR_CODE_ALREADY_IN_USE) {
          this.setLoginError(ERROR_MSGS.ERROR_MSG_ACCOUNT_EXISTS, e.code);
        } else if (e.code === ERRORS.ERROR_CODE_INVALID_EMAIL) {
          this.setLoginError(ERROR_MSGS.ERROR_MSG_INVALID_EMAIL, e.code);
        } else if (e.code === ERRORS.ERROR_CODE_WRONG_PASSWORD) {
          this.setLoginError(ERROR_MSGS.ERROR_MSG_WRONG_PASSWORD, e.code);
        } else if (e.code === ERRORS.ERROR_CODE_NOT_FOUND) {
          this.setLoginError(ERROR_MSGS.ERROR_MSG_NOT_FOUND, e.code);
        }
      }
    }
    return retVal;
  }


  async saveSnapshot(data) {
    const value = JSON.stringify(data);
    await Storage.set({ key: 'wdwtables', value });
  }

  async initializeDb() {
    console.log('initializeDb');
    try {
      const data = await this.checkUser();
      this.db.loadData(data);
      const snap = getSnapshot(this.db);
      await this.saveSnapshot(snap);
      onSnapshot(
        this.db,
        this.saveSnapshot
      );
    } catch (e) {
      console.log('initializeDb', e);
    }
    this.initializeCrashlytics();
    this.initializePush();
    console.log('finished loading');
  }

  async initializePush() {
    const self = this;
    try {
      if (this.deviceInfo.platform !== 'web') {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!');
        }

        await PushNotifications.createChannel({
          description: 'Default WDW Tables notifications',
          id: 'wdw_tables_default_channel',
          importance: 5,
          lights: true,
          name: 'WDW Tables notification channel',
          sound: 'chime.wav',
          vibration: true,
          visibility: 1
        });

        // Register with Apple / Google to receive push via APNS/FCM
        await PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener(
          'registration', 
          (token) => {
            console.log('Push registration success, token: ' + token.value);
            this.addToken(token.value);
          }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener(
          'registrationError', 
          (error) => {
            console.log('Error on registration: ' + JSON.stringify(error));
          }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
          'pushNotificationReceived', 
          (notification) => {
            console.log('Push received: ' + JSON.stringify(notification));
            if (notification.data && notification.data.extra) {
              const { data } = notification;
              let record;
              data.extra = JSON.parse(data.extra);
              switch (data.type) {
                case 'restaurant-update':
                  this.updateRestaurants();
                  break;
                case 'search-results':
                  const searchLog = data.extra;
                  this.updateSearchResults(notification, searchLog);
                  break;
                case 'search-add':
                case 'search-update':
                case 'search-delete':
                  record = data.extra.payload;
                  this.updateSearch(record);
                  break;
                case 'subscription-add':
                  record = data.extra.payload;
                  this.updateSubscription(record);
                  break;
                case 'extra-searches-add':
                  record = data.extra.payload;
                  this.updateExtraSearches(record);
                  break;
                case 'payment-add':
                  record = data.extra.payload;
                  this.updatePayment(record);
                  break;
                case 'user-update':
                  record = data.extra.payload;
                  this.updateUser(record);
                  break;
                default:
                  console.log('unhandled notification: ', data.type);
              }
            }
          }
        );
        // Method called when tapping on a notification
        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          async (notification) => {
            console.log('Push action performed: ' + JSON.stringify(notification));
            if (notification.notification && notification.notification.data && notification.notification.data.extra) {
              const searchLog  = JSON.parse(notification.notification.data.extra);
              this.addSearchLog(searchLog);
            }

            this.updateData();
            try {
              await PushNotifications.removeAllDeliveredNotifications();
            } catch (e) {
              console.log(e);
            }
          }
        );
      } else {
        this.setupWs();
      }
    } catch (e) {
      console.log('initializePush', e);
    }
  }

  addSearchLog(searchLog) {
    if (searchLog.uid) {
      const r = this.db.getSearchByUid(searchLog.uid);
      if (r) {
        const search = clone(r);
        searchLog.createdat = (spacetime(searchLog.createdat, 'UTC')).epoch;
        searchLog.updatedat = (spacetime(searchLog.updatedat, 'UTC')).epoch;
        searchLog.datesearched = (spacetime(searchLog.datesearched, 'UTC')).epoch;
        searchLog.times = Array.isArray(searchLog.times) ?
          searchLog.times :
          searchLog.times ? JSON.parse(searchLog.times) : [];
        const record = SearchLog.create(searchLog);
        search.addLog(record);
        this.db.usersearches.update(search);
      }
    }
  }

  updateSearch(search) {
    let userSearch = this.db.usersearches.records.find(r => r.id === search.id);
    if (userSearch) {
      userSearch.update(search);
    } else {
      userSearch = UserSearch.create(search);
      this.db.usersearches.update(userSearch);
    }
  }

  updateSearchResults(notification, searchLog) {
    if (notification && this.deviceInfo.platform !== 'web' && notification.title) {
      LocalNotifications.schedule({
        notifications: [
          {
            title: notification.title,
            body: notification.body,
            id: 1,
            schedule: { at: dayjs().add(1, 'seconds').toDate() },
            sound: 'chime.wav',
            attachments: null,
            actionTypeId: "",
            extra: null
          }
        ]
      });
    } else if (notification.data.notification && this.deviceInfo.platform === 'web') {
      const not = notification.data.notification;
      webNotification.showNotification(
        not.title,
        {
          body: not.body,
          icon: 'favicon.ico',
          onClick: () => {
            console.log('Notification clicked.');
          },
          autoClose: 15000
        },
        (error, hide) => {
          if (error) console.log('Unable to show notification: ', error.message);
        }
      );
      this.notificationChime.play();
    }
    this.addSearchLog(searchLog);
  }

  updateSubscription(subscription) {
    let sub = this.db.subscriptions.records.find(r => r.id === subscription.id);
    if (sub) {
      sub.update(subscription);
    } else {
      sub = Subscription.create(subscription);
      this.db.subscriptions.update(sub);
    }
  }

  updateExtraSearches(extrasearches) {
    let record = this.db.extrasearches.records.find(r => r.id === extrasearches.id);
    if (record) {
      record.update(extrasearches);
    } else {
      record = ExtraSearch.create(extrasearches);
      this.db.extrasearches.update(record);
    }
  }

  updatePayment(payment) {
    let record = this.db.payments.records.find(r => r.id === payment.id);
    if (record) {
      record.update(payment);
    } else {
      record = Payment.create(payment);
      this.db.payment.update(record);
    }
  }

  async updateRestaurants() {
    try {
      const lastUpdated = this.db.lastrestaurantupdate;
      if (lastUpdated) {
        const { data } = await this.request.get(`/restaurants/${lastUpdated}`);
        applySnapshot(this.db.restaurants.records, data.data);
      }
    } catch (e) {
      console.log('updateRestaurant', e);
    }
  }

  async addToken(t) {
    const deviceToken = this.db.devicetokens.records.find(r => r.token === t);

    if (!deviceToken) {
      const deviceInfo = await this.getDeviceInfo();
      const token = DeviceToken.create({
        userid: this.activeUser.uid,
      });
      token.setProp('token', t);
      token.setProp('type', deviceInfo.platform);
      token.setProp('userid', this.activeUser.uid);
      token.setProp('uuid', this.deviceid);
      token.setProp('name', this.devicename);
      token.setProp('createdat', dayjs().format('YYYY-MM-DD'));
      token.setProp('updatedat', dayjs().format('YYYY-MM-DD'));
      const payload = getSnapshot(token);
      try {
        const { data } = await this.request.post('/mobile/messaging/token', payload);
        this.db.devicetokens.update(token);
      } catch (e) {
        console.log('addToken', e);
      }
    } else {
      console.log('Device token already exists');
    }

  }

  async initializeCrashlytics() {
      /*
      await crashlytics.logUser({
        name: this.activeUser.displayName,
        email: this.activeUser.email,
        id: this.activeUser.uid,
      });
      */
     return true;
  }

  async setupPush() {
    const messages =  await PushNotifications.getDeliveredNotifications();
    console.log(messages);
  }

  async sendUpdate(update) {
    console.log(update);
  }

  async getAuthToken(force) {
    console.log('getAuthToken');
    try {
      if (this.activeUser && this.activeUser.uid) {
        const currentUser = isPlatform("capacitor") ? await FirebaseAuthentication.getCurrentUser() : firebaseService.getCurrentUser();
        const authToken = isPlatform("capacitor") ? await FirebaseAuthentication.getIdToken() : await currentUser.getIdToken();
        console.log('authToken', JSON.stringify(authToken, null, 2));
        this.authToken = isPlatform("capacitor")  ? authToken.token : authToken;
        this.authTokenRetrieved = dayjs();
      }
    } catch (e) {
      console.log('getAuthToken',e);
    }
    return this.authToken;
  }

  async createAccount(email, password) {
    let result;
    try {
      const data = await firebaseService.registerUser(email, password);
      result = {
        success: false,
        payload: data,
      };
    } catch (e) {
      if (this.checkError(e.code)) {
        this.pendingCred = e.credential;
        // Get sign-in methods for this email.
        const methods = await firebaseService.signInMethods(email);
        console.log(methods);
        result = {
          success: false,
          payload: {
            error: e.code,
            methods,
          }
        };
        this.setLoginError(ERROR_MSGS.ERROR_MSG_ACCOUNT_EXISTS)
      }
    }
    return result;
  }

  async getMessagingToken(force) {
    //this.messagingToken = await getToken();
    return this.messagingToken;
  }

  async getDeviceInfo() {
    const info = await Device.getInfo();
    this.deviceInfo = info;
    this.deviceName = `${info.manufacturer} ${info.model}`;
    this.deviceId = info.uuid;

    StripeNative.initialize({
      publishableKey: settings.stripe.publishableKey,
    });
    return info;
  }

  setupRequest() {
    const self = this;
    /*
    const AxiosOfflineAdapter = AxiosOffline({
      defaultAdapter: axios.defaults.adapter,
      storageName: "axios-offline",
    });
    */
    const request = axios.create({
      baseURL: url,
      timeout: 5000,
    });
    request.interceptors.request.use(
      async (request) => {
        try {
          request.headers.common.Authorization = `Bearer ${self.authToken}`;
        } catch (e) {
          console.log('axios error', e);
          if (e.response && e.response.data.errInfo.code === "auth/id-token-expired") {
            await self.nativeSignOut();
          } else {
            throw e;
          }
        }
        return request;
      }
    );
    self.request = request;
  }

  async checkUser() {
    let retVal;
    try {
      const user = {
        uid: this.activeUser.uid,
        email: this.activeUser.email,
      };
      const { data } = await this.request.post('/user', user);
      retVal = data.data;
      this.setLastUpdate();
    } catch (error) {
      console.log('checkUser', error);
    }

    return retVal;
  }

  setLastUpdate() {
    const record = this.db.settings.records.find(r => r.key === "lastupdate");
    const updated = dayjs().valueOf().toString();
    if (record) {
      record.setProp('value', updated);
      //this.db.settings.update(record);
    } else {
      const setting = Setting.create();
      setting.setProp('key', 'lastupdate');
      setting.setProp('value', updated);
      this.db.settings.update(setting);
    }
  }

  getLastUpdate() {
    let retVal;
    const record = this.db.settings.records.find(r => r.key === "lastupdate");
    if (record) {
      retVal = record.value;
    }

    return retVal;
  }

  newPayment() {
    const payment = Payment.create();
    if (payment) {
      const extraSearches = ExtraSearch.create({
        userid: this.activeUser.uid,
        numberofsearches: 0,
      });
      let subscription;
      if (this.currentSubscription) {
        subscription = this.currentSubscription;
        payment.setProp('type', 'searches');
        payment.setProp('amount', '0.00');
      } else {
        const expires = dayjs().add(6, 'month').valueOf();
        subscription = Subscription.create();
        subscription.setProp('userid', this.activeUser.uid);
        subscription.setProp('expires', expires);
      }
      payment.setProp('subscriptionid', subscription.id);
      const card = {
        number: null,
        expiry: null,
        cvc: null,
        zip: null,
      }
      const expires = dayjs().add(6, 'months').valueOf();
      payment.setProp('userid', this.activeUser.uid);
      payment.setProp('expires', expires);
      extraSearches.setProp('subscription', subscription.id);
      extraSearches.setProp('userid', this.activeUser.uid);
      this.payment = payment;
      this.subscription = subscription;
      this.extrasearches = extraSearches;
      this.card = card;
    } else {
      const error = new Error('New payment cannot be created');
      throw error;
    }

    return payment;
  }

  newSearch() {
    const record = UserSearch.create();
    if (record) {
      record.setProp('userid', this.activeUser.uid);
      this.cloned = record;
    } else {
      const error = new Error('New search cannot be created');
      throw error;
    }

    return record;
  }

  cloneRecord(table, id) {
    const record = this.db[table].records.find(r => r.id === id);
    if (record) {
      this.cloned = clone(record);
    } else {
      const error = new Error(`Record ${id} cannot be found in table: ${table}`);
      throw error;
    }

    return record;
  }

  cloneUser() {
    const record = this.db.user;
    if (record) {
      this.cloned = clone(record);
      if (!this.cloned.phone && this.activeUser.phonenumber) {
        this.cloned.setProp('phone', new AsYouType().input(this.activeUser.phonenumber));
      } else if (!this.cloned.phone && !this.activeUser.phonenumber) {
        this.cloned.setProp('phone', '+1');
      } else {
        this.cloned.setProp('phone', new AsYouType().input(this.cloned.phone));
      }

      if (!this.cloned.email) {
        this.cloned.setProp('email', this.activeUser.email);
      }
      if (!this.cloned.displayname) {
        this.cloned.setProp('displayname', this.activeUser.displayname);
      }
    } else {
      const error = new Error(`User record cannot be found in`);
      throw error;
    }

    return record;
  }

  updateRecordProperty(prop, e) {
    console.log(prop, e);
    let ignoreUpdate = false;
    let value = (e.detail) ? e.detail.value : null;
    let property = prop;
    if (prop === 'date' || prop === 'time') {
      const utc = spacetime(e, 'America/New_York').goto('UTC');
      //const origDate = spacetime(this.cloned.date.toISOString(), 'UTC');
      //const newDate = (prop === 'date') ? utc.format('{iso-short}') : origDate.format('{iso-short}');
      //const newTime = (prop === 'time') ? utc.format('{time}') : origDate.format('{time}');
      //const newDateTime = spacetime(`${newDate} ${newTime}`, 'UTC');
      value = dayjs.utc(utc.epoch);
      property = 'date';
    } else if (prop === 'enablesms' || prop === 'enableemail') {
      value = e.detail.checked;
    } else if (prop === 'restaurant') {
      if (e.id) {
        value = e.id;
        const rest = e.toJSON();
        const newRestaurant = Restaurant.create(rest);
        this.cloned.setProp('restaurantdata', newRestaurant);
      } else {
        ignoreUpdate = true;
      }
    } else if (prop === 'phone') {
      value = new AsYouType().input(value);
    }
    if (!ignoreUpdate) this.cloned.setProp(property, value);
  }

  async updateRecord(table) {
    let snap = getSnapshot(this.cloned);
    const url = (table === 'user') ? '/user' : '/search';
    this.db[table].update(this.cloned);
    try {
      const { data } = await this.request.post(url, snap);
    } catch (e) {
      console.log('updateRecord', e);
    }
    this.cloned = null;
  }

  updateUser(user) {
    this.db.user.update(user);
  }

  async deleteSearch(search) {
    const snap = getSnapshot(search);
    search.delete();
    try {
      const { data } = await this.request.delete(`/search/${snap.id}`);
    } catch (e) {
      console.log('deleteSearch', e);
    }
    return true;
  }

  updateSubscriptionProp(prop, e) {
    let value = (e.detail && e.detail.value) ?  e.detail.value : e.target.value;
    let subscriptionCost = 5.99;
    let extraSearchesCost = 0.00;
    switch (prop) {
      case "card":
      case "expiry":
      case "cvc":
        value = (value) ? value.replace(/ /g,'') : null;
        break;
      default:
        value = value;
    }

    if (prop === 'extrasearches') {
      if (this.extrasearches) {
        this.extrasearches.setProp('numberofsearches', value);
      } else {
        //this.ext
      }
    } else if (prop === 'type') {
      this.subscription.setProp('type', value);
      this.payment.setProp('type', value);
    } else {
      this.card[prop] = value;
    }

    switch (this.payment.type) {
      case "searches":
        subscriptionCost = 0.00;
        break;
      case "standard":
      default:
        subscriptionCost = 5.99;
    }

    if (this.extrasearches && this.extrasearches.numberofsearches) {
      extraSearchesCost = (this.extrasearches.numberofsearches / 3) * 0.99;
    }

    if (this.payment) {
      const total = (subscriptionCost + extraSearchesCost).toFixed(2);
      this.payment.setProp('amount', total);
    }
  }

  get stripeKey() {
    return settings.stripe.publishableKey;
  }

  get totalPurchaseSearches() {
    let subscription = 8;
    let extraSearches = 0
    if (this.payment) {
      switch (this.payment.type) {
        case "searches":
          subscription = 0;
          break;
        case "standard":
        default:
          subscription = 8;
      }
    }

    if (this.extrasearches && this.extrasearches.numberofsearches) {
      extraSearches = this.extrasearches.numberofsearches;
    }

    return subscription + extraSearches;
  }

  async getPaymentToken(amount) {
    try {
      amount = parseFloat(amount) * 100;
      const { data } = await this.request.post(
        '/paymentIntent',
        {
          amount: `${amount}`,
          email: this.activeUser.email,
        }
      );
      this.paymentTokenInfo = data.data.paymentIntent;
      this.customerTokenInfo = data.data.customer;
      this.ephemeralKeyInfo = data.data.ephemeralKey;
      return data.data;
    } catch (e) {
      throw e;
    }
  }

  async updatePaymentToken(amount) {
    try {
      amount = parseFloat(amount) * 100;
      const { data } = await this.request.put(
        `/paymentIntent/${this.paymentToken.id}`,
        {
          amount: `${amount}`,
        }
      );
      this.paymentTokenInfo = data.data.paymentIntent;
      return data.data;
    } catch (e) {
      throw e;
    }
  }

  async getPaymentIntent(id) {
    try {
      const { data } = await this.request.get(`/paymentIntent/${id}`);
      return data.data.paymentIntent;
    } catch (e) {
      throw e;
    }
  }

  async savePayment(transaction) {
    let snapPayment;
    let snapExtraSearches;
    let snapSubscription;
    let retVal;
    let token;
    const now = dayjs().valueOf();
    if (this.extrasearches.numberofsearches) {
      snapExtraSearches = getSnapshot(this.extrasearches);
    }
    if (this.subscription) {
      snapSubscription = getSnapshot(this.subscription);
    }
    try {
      //const method = transaction.paymentIntent.payment_method_details;
      //const amount = parseFloat(this.payment.amount) * 100;
      this.payment.setProp('transid', transaction.paymentIntent.payment_method);
      this.payment.setProp('date', transaction.paymentIntent.created);
      this.payment.setProp('createdat', now);
      this.extrasearches.setProp('createdat', now);
      this.extrasearches.setProp('updatedat', now);
      snapPayment = getSnapshot(this.payment)
      const payload = {
        payment: snapPayment,
        token,
        extraSearches: snapExtraSearches,
        subscription: !this.currentSubscription ? snapSubscription : null,
      };
      const { data } = await this.request.post('/charge', payload);
      this.payment.setProp('cardtype', data.data.payment.cardtype);
      this.payment.setProp('last4', data.data.payment.last4);
      this.db.payments.update(this.payment);
      if (this.extrasearches.numberofsearches) {
        this.db.extrasearches.update(this.extrasearches);
      }
      if (!this.currentSubscription && this.subscription) {
        this.db.subscriptions.update(this.subscription);
      }
      /*
      const { data } = await this.request.post('/charge', payload);
      if (data.success) {
        const { payment } = data.data; 
        this.payment.setProp('transid', payment.transId);
        this.payment.setProp('date', dayjs(payment.date).valueOf());
        this.db.payments.update(this.payment);
        if (this.extrasearches.numberofsearches) {
          this.db.extrasearches.update(this.extraSearches);
        }
        if (!this.currentSubscription && this.subscription) {
          this.db.subscriptions.update(this.subscription);
        }

        this.cancelSubscriptionPurchase();
      }
      */
      retVal = data;
    } catch (e) {
      console.log('savePayment', e);
      console.log(e.response);
      this.cancelSubscriptionPurchase();
      retVal = {
        success: false,
        message: (e.response && e.response.data) ? e.response.data.data.message : 'Request failed',
      };
    }
    return retVal;
  }

  cancelSubscriptionPurchase() {
    this.extraSearches = null;
    this.subscription = null;
    this.payment = null;
    this.card = null;

    return true;
  }

  async openTimeUrl(time) {
    const url = `https://disneyworld.disney.go.com/dining-reservation/setup-order/table-service/?offerId[]=${time.url}`;
    await Browser.open({ url });
  }

  async getRestaurants() {
    try {
      const { data } = await this.request.get('/restaurants');
      this.restaurants = data.data;
    } catch (e) {
      console.log('getRestaurants', e);
    }
    return this.restaurants;
  }

  get totalsearches() {
    let number = 2;
    const type = {
      standard: {
        number: 8,
      },
      pro: {
        number: 101001,
      },
    };
    const now = dayjs().valueOf();
    const currentSubscription = this.db.subscriptions.records.find(s => s.expires >= now && s.deletedat === null);
    if (currentSubscription) {
      const extraSearches = this.db.extrasearches.records.filter(e => e.subscription === currentSubscription.id && e.deletedat === null);
      const numExtraSearches = extraSearches.reduce((a, b) => a + b.numberofsearches, 0);
      if (currentSubscription.unlimited) {
        number = 101001;
      } else {
        number = type[currentSubscription.type].number + numExtraSearches;
      }
    }
    return number;
  }

  get availableSearches() {
    let number = 2;
    const type = {
      standard: {
        number: 8,
      },
      pro: {
        number: 101001,
      },
    };
    const now = dayjs();
    const activesearches = this.db.usersearches.records.filter(s => s.enabled && s.date.isAfter(now) && s.deletedat === null);
    const currentSubscription = this.db.subscriptions.records.find(s => s.expires.isAfter(now) && s.deletedat === null);
    if (currentSubscription) {
      const extrasearches = this.db.extrasearches.records.filter(e => e.subscription === currentSubscription.id && e.deletedat === null);
      const numextrasearches = extrasearches.reduce((a, b) => a + b.numberofsearches, 0);
      if (currentSubscription.unlimited) {
        number = 101001;
      } else {
        number = type[currentSubscription.type].number + numextrasearches;
      }
    }
    const availableSearches = number - activesearches.length;
    return availableSearches;
  }

  extraSearchesBySubscriptionId(subId) {
    const extraSearches = this.db.extrasearches.records.filter(e => e.subscription === subId && e.deletedat === null);
    const numExtraSearches = extraSearches.reduce((a, b) => a + b.numberofsearches, 0);
    return numExtraSearches;
  }

  get currentSubscription() {
    const now = dayjs().valueOf();
    const currentSubscription = this.db.subscriptions.records.find(s => s.expires >= now && s.deletedat === null);
    return currentSubscription;
  }

  get activesearches() {
    const now = dayjs().valueOf();
    const activesearches = this.db.usersearches.records.filter(s => s.enabled && s.date >= now && s.deletedat === null);
    return activesearches;
  }

  checkCanAdd() {
    return this.availableSearches;
  }

  sendWs = (payload) => {
    this.ws.send(JSON.stringify(payload))
  }

  wsOpen = (event) => {
    this.sendWs({ type: 'hello', userId: this.userId })
  }

  wsMessage(event) {
    let idx;
    let record;
    const { payload } = JSON.parse(event.data);

    switch (payload.type) {
      case 'restaurant-update':
        this.updateRestaurants();
        break;
      case 'search-results':
        record = ('data' in payload.data) ? payload.data.data : payload.data;
        record.extra = JSON.parse(record.extra);
        this.updateSearchResults(payload, record.extra);
        break;
      case 'search-add':
      case 'search-update':
      case 'search-delete':
        record = ('data' in payload.data) ? payload.data.data : payload.data;
        if (!record.logs) {
          record.logs = [];
        }
        this.updateSearch(record);
        break;
      case 'subscription-add':
        record = ('data' in payload.data) ? payload.data.data : payload.data;
        this.updateSubscription(record);
        break;
      case 'extra-searches-add':
        record = ('data' in payload.data) ? payload.data.data : payload.data;
        this.updateExtraSearches(record);
        break;
      case 'payment-add':
        record = ('data' in payload.data) ? payload.data.data : payload.data;
        this.updatePayment(record);
        break;
      case 'user-update':
        record = ('data' in payload.data) ? payload.data.data : payload.data;
        this.updateUser(record);
        break;
      default:
        console.log('unhandled payload', payload.data)
        break
    }
  }

  setupWs() {
    try {
      const userId = this.activeUser.uid;
      const url = `${settings.websocketUrl}?token=${userId}`;
      this.ws = new RobustWebSocket(
        url,
        null,
        {
          // The number of milliseconds to wait before a connection is considered to have timed out. Defaults to 4 seconds.
          timeout: 4000,
          // A function that given a CloseEvent or an online event (https://developer.mozilla.org/en-US/docs/Online_and_offline_events) and the `RobustWebSocket`,
          // will return the number of milliseconds to wait to reconnect, or a non-Number to not reconnect.
          // see below for more examples; below is the default functionality.
          shouldReconnect: (event, ws) => {
            return Math.pow(1.5, ws.attempts) * 500
          },
          // A boolean indicating whether or not to open the connection automatically. Defaults to true, matching native [WebSocket] behavior.
          // You can open the websocket by calling `open()` when you are ready. You can close and re-open the RobustWebSocket instance as much as you wish.
          automaticOpen: true,
        },
      );

      this.ws.addEventListener('open', this.wsOpen.bind(this));
      this.ws.addEventListener('message', this.wsMessage.bind(this));
      this.notificationChime = new Audio('/assets/chime.wav');
    } catch (e) {
      console.log('setupWs', e);
    }
  }

  setLoginError(message, code) {
    this.loginError.error = true;
    this.loginError.message = message;
    this.loginError.code = code;
  }

  clearLoginError() {
    this.loginError.error = false;
    this.loginError.message = null;
    this.loginError.code = null;
  }
  
  async resetPassword(email) {
    let retVal;

    try {
      const { data } = await axios.post(`${settings.serverUrl}/reset/password`, { email });
      retVal = data;
    } catch (e) {
      retVal = e;
    }

    return retVal;
  }

  async getUserSearches() {
    let retVal;
    const user = this.activeUser;
    try {
      const { data } = await this.request.get(`/user/searches/all`);
      retVal = data;
    } catch (e) {
      retVal = e;
      throw e;
    }

    return retVal;
  }
};
