import React, { useState } from 'react';
import {
  IonAvatar,
  IonLabel,
  IonItem,
  IonContent,
  IonNote,
  IonList,
  IonButtons,
  IonIcon,
  IonPage,
  IonBackButton,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonButton,
  IonInput
} from '@ionic/react';
import { useHistory } from "react-router-dom";
import { observer } from 'mobx-react-lite';

import { useStores } from '../hooks/use-stores';

const ForgotPassword = () => {
  const history = useHistory();
  const store = useStores();
  const [email, setEmail] = useState(null);
  const reset = async () => {
    try {
      await store.resetPassword(email);
    } catch (e) {
      console.log(e);
    }
    history.push('/login');
  };
  const cancel = () => {
    history.push('/login');
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              onClick={cancel}
              defaultHref="/login"
            />
          </IonButtons>
          <IonTitle>Reset Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScrollStart={() => {}}
        onIonScroll={() => {}}
        onIonScrollEnd={() => {}}
      >
        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            type="email"
            name="email"
            onIonChange={e => setEmail(e.detail.value)}
          />
        </IonItem>
      </IonContent>
      <IonButton
        disabled={!email}
        expand="full"
        color="primary"
        onClick={reset}
      >
        Reset Password
      </IonButton>
    </IonPage>
  );
};

export default observer(ForgotPassword);