const env = process.env.NODE_ENV;
const development = {
  serverUrl: 'https://api.wdwtables.com', // 'https://wdwtables.dev.int',
  stripe: {
    publishableKey: 'pk_test_GCwYIspYUMg4bGTCpCI5OM4J',
    merchantId: 'MERCHANT_ID', // Optional
    androidPayMode: 'test', // Android only
  },
  websocketUrl: 'wss://ws.wdwtables.com', // 'ws://localhost:3001',
  db: {
    name: 'wdwtables',
  }
};

const production = {
  serverUrl: 'https://api.wdwtables.com',
  stripe: {
    publishableKey: 'pk_test_GCwYIspYUMg4bGTCpCI5OM4J',
    merchantId: 'MERCHANT_ID', // Optional
    androidPayMode: 'test', // Android only
  },
  websocketUrl: 'wss://ws.wdwtables.com',
  db: {
    name: 'wdwtables',
  }
};

module.exports =  env === 'production' ? production : development;
