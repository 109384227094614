import React, { useState } from 'react';
import {
  IonButton,
  IonItem,
  IonHeader,
  IonContent,
  IonLabel,
  IonInput,
  IonPage,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import { observer } from 'mobx-react-lite';
import { useHistory } from "react-router-dom";

import { useStores } from '../hooks/use-stores';

const Waiting = () => {
  const history = useHistory();
  const store = useStores();
  return(
    <IonPage>
      <IonHeader>
        {store.deviceInfo.platform === 'web' ?
          <IonToolbar color="primary">
            <IonTitle size="large">WDW Tables</IonTitle>
          </IonToolbar> : null
        }
      </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScrollStart={() => {}}
        onIonScroll={() => {}}
        onIonScrollEnd={() => {}}
      >
        <ion-text>
          <h2>Syncing content...</h2>
        </ion-text>
      </IonContent>
    </IonPage>
  );
};

export default observer(Waiting);