import React from 'react';
import {
  IonAvatar,
  IonLabel,
  IonItem,
  IonContent,
  IonNote,
  IonList,
  IonText,
  IonIcon,
  IonPage,
  IonFab,
  IonFabButton,
  IonHeader,
  IonToolbar,
  IonTitle
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { card, add, trash, time } from 'ionicons/icons'
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import visa from 'payment-icons/min/mono/visa.svg';
import amex from 'payment-icons/min/mono/amex.svg';

import { useStores } from '../hooks/use-stores';

const styles = {
  capitalize: {
    textTransform: 'capitalize',
  },
  label: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  column: {
    width: '80%',
  },
  lastColumn: {
    width: '20%',
  },
  small: {
    fontSize: '0.85em',
  },
};

const Subscription = observer(({ sub }) => {
  const store = useStores();
  const extraSearches = store.extraSearchesBySubscriptionId(sub.id);
  const totalPaid = 5.99 + ((extraSearches/3) * 0.99);
  const totalSearches = 8 + extraSearches;
  return(
    <IonItem key={sub.id} routerLink={`/subscription/${sub.id}/edit`}>
      <IonAvatar slot="start">
        <IonIcon icon={card} size="large" />
      </IonAvatar>
      <IonLabel style={styles.label}>
        <div style={styles.column}>
          <h2 style={styles.capitalize}>{sub.type}</h2>
          <IonText
            color="medium"
            style={styles.small}
          >
            Included: 8 | Extra: {extraSearches} | Total: {totalSearches}
          </IonText> <br />
          <IonText
            color="medium"
            style={styles.small}
          >
            Purchased: {dayjs(sub.createdat).utcOffset(-300).format('dddd, MMM D, YYYY')}
          </IonText> <br />
          <IonText
            color="medium"
            style={styles.small}
          >
            Expires: {dayjs(sub.expires).utcOffset(-300).format('dddd, MMM D, YYYY')}
          </IonText>
        </div>
        <div style={styles.lastColumn}>
          <h2 style={styles.capitalize}>${totalPaid.toFixed(2)}</h2>
        </div>
      </IonLabel>
    </IonItem>
  );
});

const TabSubscriptions = () => {
  const store = useStores();
  const history = useHistory();
  const addSub = () => {
    history.push('/subscriptions/add');
  };
  return (
    <IonPage>
      <IonHeader>
      {store.deviceInfo.platform === 'web' ?
        <IonToolbar color="primary">
          <IonTitle size="large">WDW Tables</IonTitle>
        </IonToolbar> : null
      }
    </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScrollStart={() => {}}
        onIonScroll={() => {}}
        onIonScrollEnd={() => {}}
      >
        <IonList>
          {store.db.listSubscriptions.map(s => <Subscription key={s.id} sub={s} />)}
        </IonList>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            color="secondary"
            onClick={addSub}
          >
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default observer(TabSubscriptions);