import { when, reaction } from 'mobx';
import { types, getParent, getSnapshot, applySnapshot, destroy } from 'mobx-state-tree';
import cuid from 'cuid';
import dayjs from 'dayjs'

import DateTime from './date';

export const Payment = types
  .model("Payment", {
    id: types.optional(types.identifier, () => cuid()),
    userid: types.optional(types.maybeNull(types.string), null),
    amount:	types.optional(types.string, "5.99"),
    type: types.optional(types.string, 'standard'),
    date: types.optional(DateTime, null),
    expires: types.optional(DateTime, () => dayjs().add(6, 'months').valueOf()),
    transid: types.optional(types.maybeNull(types.string), null),
    subscriptionid: types.optional(types.maybeNull(types.string), null),
    cardtype: types.optional(types.maybeNull(types.string), null),
    last4: types.optional(types.maybeNull(types.string), null),
    discountcode: types.optional(types.maybeNull(types.integer), null),
    discount:	types.optional(types.maybeNull(types.number), null),
    failurecode: types.optional(types.maybeNull(types.string), null),
    failuremess: types.optional(types.maybeNull(types.string), null),
    createdat: types.optional(DateTime, null),
    updatedat: types.optional(DateTime, null),
    deletedat: types.optional(DateTime, null),
  })
  .actions(self => ({
    setProp(prop, value) {
      self[prop] = value;
    },
    update(props) {
      const snap = getSnapshot(self);
      const record = Object.assign(
        {},
        snap,
        props
      );
      applySnapshot(self, record);
    },
    delete() {
      self.deletedat = dayjs().valueOf();
    },
    remove() {
      getParent(self, 2).remove(self);
    }
  })
);

export const Payments = types
  .model("Payments", {
    records: types.array(Payment),
  })
  .actions(self => ({
    add(record) {
      let r = self.records.find(r => r.id === record.id);
      if (!r) {
        self.records.push(record);
        r = self.records[self.records.length - 1];
      }
      return r;
    },
    update(record) {
      let r = self.records.find(r => r.id === record.id);
      if (r) {
        r.remove();
      }
      self.records.push(record);
      return record;
    },
    remove(record) {
      destroy(record);
    },
    clear() {
      self.records.clear();
    },
  })
);