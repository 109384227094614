import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import {
  IonContent,
  IonHeader,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonBadge,
  IonRouterOutlet,
} from '@ionic/react';
import { card, home, settings } from "ionicons/icons";

import { useStores } from '../hooks/use-stores';
import PrivateRoute from '../components/PrivateRoute';
import TabHome from "../pages/TabHome";
import TabProfile from "../pages/TabProfile";
import TabSubscriptions from "../pages/TabSubscriptions";
import Waiting from "../pages/Waiting";
//
// value is used to let us know what view to render
//

const styles = {
  container: {
    paddingTop: 50,
  },
};

const _Profile = (props) => {
  const store = useStores();

  useEffect(() => {
    store.cloneUser();
  }, []);

  return <TabProfile {...props} />;
};

const _Home = () => {
  const store = useStores();
  let retVal = <TabHome />;
  if (!store.activeUser) {
    retVal = <Waiting />;
  }
  return retVal;
};

const TabContainer = () => {
  return (
    <React.Fragment>
      <IonTabs>
        <IonRouterOutlet>
          <PrivateRoute path="/tab/home" component={TabHome} exact={true} />
          <PrivateRoute path="/tab/subscriptions" component={TabSubscriptions} exact={true} />
          <PrivateRoute path="/tab/profile" component={_Profile} exact={true} />
          <Route exact path="/home" render={() => <Redirect to="/tab/home" />} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/tab/home">
            <IonIcon icon={home} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="card" href="/tab/subscriptions">
            <IonIcon icon={card} />
            <IonLabel>Subscriptions</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/tab/profile">
            <IonIcon icon={settings} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </React.Fragment>
  );
};

export default observer(TabContainer);