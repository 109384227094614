import { when, reaction } from 'mobx';
import { types, getParent, getSnapshot, applySnapshot, destroy } from 'mobx-state-tree';
import cuid from 'cuid';
import dayjs from 'dayjs';

import DateTime from './date';

export const Time = types
  .model("Time", {
    time: types.optional(types.maybeNull(types.string), null),
    url: types.optional(types.maybeNull(types.string), null),
  })
  .actions(self => ({
    setProp(prop, value) {
      self[prop] = value;
    },
    remove() {
      getParent(self, 2).remove(self);
    }
  })
);

export const SearchLog = types
  .model("SearchLog", {
    id: types.optional(types.identifier, () => cuid()),
    uid: types.optional(types.maybeNull(types.string), null),
    datesearched: types.optional(DateTime, null),
    message: types.optional(types.maybeNull(types.string), null),
    foundseats:	types.optional(types.boolean, false),
    times: types.optional(types.maybeNull(types.array(Time)), []),
    createdat: types.optional(DateTime, null),
    updatedat: types.optional(DateTime, null),
    deletedat: types.optional(DateTime, null),
    urls: types.optional(types.maybeNull(types.array(types.string)), null),
  })
  .actions(self => ({
    setProp(prop, value) {
      self[prop] = value;
    },
    delete() {
      self.deletedat = dayjs().valueOf();
    },
    remove() {
      getParent(self, 2).remove(self);
    }
  })
);

export const SearchLogs = types
  .model("SearchLogs", {
    records: types.array(SearchLog),
  })
  .actions(self => ({
    add(record) {
      let r = self.records.find(r => r.id === record.id);
      if (!r) {
        self.records.push(record);
        r = self.records[self.records.length - 1];
      }
      return r;
    },
    update(props) {
      const snap = getSnapshot(self);
      const record = Object.assign(
        {},
        snap,
        props
      );
      applySnapshot(self, record);
    },
    update(record) {
      let r = self.records.find(r => r.id === record.id);
      if (r) {
        r.remove();
      }
      self.records.push(record);
      return record;
    },
    remove(record) {
      destroy(record);
    },
    clear() {
      self.records.clear();
    },
  })
);