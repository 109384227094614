import React, { useEffect } from "react";
import { Redirect, Route,useHistory } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonBadge,
  IonRouterOutlet,
} from '@ionic/react';
import { card, home, settings } from "ionicons/icons";
import { 
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';

import { useStores } from '../hooks/use-stores';

const styles = {
  container: {
    paddingTop: 50,
  },
};

const WebPayment = ({show, setErrorMessage, showErrorToast, showLoading}) => {
  const history = useHistory();
  const store = useStores();
  const stripe = useStripe();
  const elements = useElements();
  const pay = async () => {
    try {
      showLoading(true);
      const payload = await stripe.confirmPayment(
        {
          elements,
          confirmParams: {
            return_url: 'https://example.com',
          },
          redirect: 'if_required'
        }
      );
      console.log(payload);
      if (!payload.error) {
        await store.savePayment(payload);
        show(false);
        history.push('/tab/subscriptions');
      } else {
        showLoading(false);
        setErrorMessage(payload.error.message);
        showErrorToast(true);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage(e.message);
      showErrorToast(true);
      //history.push('/tab/subscriptions');
    }
  };

  return (
    <IonContent>
      <IonGrid>
        <IonRow>
          <IonCol>
            <PaymentElement />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonButton
        expand="full"
        color="primary"
        onClick={pay}
      >
        Submit
      </IonButton>
    </IonContent>
  );
};

export default observer(WebPayment);