import React, { useState, useRef } from 'react';
import {
  IonItem,
  IonContent,
  IonLabel,
  IonList,
  IonRange,
  IonDatetime,
  IonToggle,
  IonPage,
  IonModal,
  IonButton,
  IonButtons,
  IonBackButton,
  IonToolbar,
  IonHeader,
  IonSearchbar,
  IonTitle,
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemDivider,
  useIonPicker,
  IonText,
  IonPopover,
} from '@ionic/react';
import {
  peopleCircleOutline,
  timeOutline,
  fastFoodOutline,
  phonePortraitOutline,
  mailOutline,
  calendarOutline,
} from 'ionicons/icons';
import { useHistory } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import spacetime from 'spacetime';
import TimeAgo from 'react-timeago';

import { useStores } from '../hooks/use-stores';

dayjs.extend(utcPlugin);

const styles = {
  label: {
    fontSize: '1.2em',
    fontWeight: '600',
  },
  text: {
    fontSize: '1.0em',
    fontWeight: '300',
  },
  container: {
    display: 'flex',
    padding: '10px 15px',
  },
  leftColumn: {
    width: '32px',
    marginRight: '32px',
  },
  middleColumn: {
    marginLeft: '5px'
  }
};

const Edit = () => {
  const history = useHistory();
  const store = useStores();
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState(null);
  const initDate = store.cloned.date ?
    spacetime(store.cloned.date.toISOString(), 'UTC').goto('America/New_York').format('{iso}') :
    spacetime().goto('America/New_York').format('{iso}');
  const [date, setDate] = useState(initDate);
  const [showPopover, setShowPopover] = useState(false);
  const customDateTime = useRef(); 
  const [ present ] = useIonPicker();

  const selectRestaurant = (e, r) => {
    store.updateRecordProperty('restaurant', r)
    setShowModal(false);
  };
  const save = () => {
    if (store.cloned.restaurant) {
      store.updateRecordProperty('date', date);
      history.push('/tab/home');
      store.updateRecord('usersearches');
    }
  };
  const cancel = () => {
    history.push('/tab/home');
  };
  const goCurrent = () => {
    if (customDateTime === undefined) return;
    customDateTime.current.reset()
  };
  const showPicker = () => {
    const time = spacetime(date, 'America/New_York').format('{iso-short}');
    const hour = spacetime(date, 'America/New_York').format('{hour}');
    const ampm = spacetime(date, 'America/New_York').format('{ampm}');
    const minute = spacetime(date, 'America/New_York').format('{minute-pad}');
    let hours = [];
    let minutes = [
      { text: '00', value: '00' },
      { text: '15', value: '15' },
      { text: '30', value: '30' },
      { text: '45', value: '45' },
    ];
    let selectedMinute = 0;
    minutes.forEach((min, idx) => {
      if (min.value === minute) selectedMinute = idx;
    });
    for (let i=0; i<=11; i++) {
      if (i === 0) {
        hours.push(
          {
            text: '12',
            value: 0,
          }
        );
      } else {
        const val = i.toString().padStart(2, '0')
        hours.push(
          {
            text: val,
            value: i,
          }
        );
      }
    };
    present(
      [
        {
          name: 'hour',
          selectedIndex: hour === '12' ? 0 : parseInt(hour),
          options: hours,
        },
        {
          name: 'minutes',
          selectedIndex: selectedMinute,
          options: minutes,
        },
        {
          name: 'ampm',
          selectedIndex: ampm === 'am' ? 0 : 1,
          options: [
            { text: 'AM', value: 'am' },
            { text: 'PM', value: 'pm' },
          ],
        },
      ],
      [
        {
          text: 'Confirm',
          handler: (selected) => {
            let hour = selected.hour.value;
            if (selected.ampm.value === 'pm') {
              hour = hour + 12;
            }
            setDate(`${time}T${hour}:${selected.minutes.value}`);
          },
        },
      ]
    )
  };

  const updateDate = (e) => {
    const time = spacetime(date, 'America/New_York').format('{time-24}');
    setDate(`${e.detail.value}T${time}`);
    setShowPopover(false);
    //store.updateRecordProperty('date', e);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              onClick={() => cancel()}
              defaultHref="/tab/home"
            />
          </IonButtons>
          <IonTitle>Edit Search</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScrollStart={() => {}}
        onIonScroll={() => {}}
        onIonScrollEnd={() => {}}
      >
        <IonModal isOpen={showModal}>
          <IonToolbar>
            <IonSearchbar onIonChange={(e) => setSearch(e.currentTarget.value)} />
          </IonToolbar>
          <IonContent>
            <IonList>
              {store.db.findRestaurant(search).map(r => (
                <IonItem button key={r.id} onClick={(e) => selectRestaurant(e, r)}>{r.name}</IonItem>
              ))}
            </IonList>
          </IonContent>
          <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
        </IonModal>
        {store.cloned ?
          <React.Fragment>
            <IonItem button onClick={() => setShowModal(true)} lines="full">
              <IonIcon size="large" slot="start" icon={fastFoodOutline} />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <div style={{ ...styles.label, marginTop: '10px' }}>Restaurant</div>
                  <div style={styles.text}>{store.cloned.restaurantdata.id ? store.cloned.restaurantdata.name : 'Select Restaurant'}</div>
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem lines="full">
              <IonIcon size="large" slot="start" icon={peopleCircleOutline} />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <div style={{ ...styles.label, marginTop: '10px' }}>Party Size</div>
                  <IonRange
                    min={1}
                    max={20}
                    step={1}
                    snaps
                    pin
                    color="secondary"
                    value={store.cloned.partysize}
                    onIonChange={e => store.updateRecordProperty('partysize', e)}
                  >
                  </IonRange>
                </IonText>
              </IonLabel>
              <IonLabel slot="end">{store.cloned.partysize}</IonLabel>
            </IonItem>
            <IonItem button onClick={() => setShowPopover(true)} lines="full">
              <IonIcon size="large" slot="start" icon={calendarOutline} />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <h2 style={{ ...styles.label, marginTop: '10px' }}>Date</h2>
                  <div style={styles.text}>{dayjs(date).format('dddd MMMM DD, YYYY')}</div>
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem button onClick={showPicker} lines="full">
              <IonIcon size="large" slot="start" icon={timeOutline} />
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <h2 style={{ ...styles.label, marginTop: '10px' }}>Time</h2>
                  <div style={styles.text}>{dayjs(date).format('h:mm A')}</div>
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem lines="full">
            <IonIcon size="large" slot="start" icon={phonePortraitOutline} />
              <IonLabel>Enable Text Notifications</IonLabel>
              <IonToggle
                checked={store.cloned.enablesms}
                value={true}
                onIonChange={e => store.updateRecordProperty('enablesms', e)}
              />
            </IonItem>
            <IonItem lines="full">
            <IonIcon size="large" slot="start" icon={mailOutline} />
              <IonLabel>Enable Email Notifications</IonLabel>
              <IonToggle
                checked={store.cloned.enableemail}
                value={true}
                onIonChange={e => store.updateRecordProperty('enableemail', e)}
              />
            </IonItem>
            <IonPopover
              class='popoverDatetime'
              side='top'
              size='cover'
              alignment='end'
              reference='event'
              isOpen={showPopover}
              onDidDismiss={() => setShowPopover(false)}
            >

                <IonDatetime
                  size="cover"
                  ref={customDateTime}
                  value={dayjs(date).format('YYYY-MM-DD')}
                  onIonChange={updateDate}
                  presentation="date"
                />
            </IonPopover>
          </React.Fragment> :
          null
        }
      </IonContent>
      <IonButton
        disabled={!store.cloned.restaurant}
        expand="full"
        color="primary"
        onClick={save}
      >
        Save
      </IonButton>
    </IonPage>
  );

};

export default observer(Edit);