import React, { useState } from 'react';
import {
  IonAvatar,
  IonLabel,
  IonItem,
  IonContent,
  IonNote,
  IonList,
  IonListHeader,
  IonIcon,
  IonPage,
  IonFab,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonButton,
  IonInput
} from '@ionic/react';
import { cash, add, trash, time } from 'ionicons/icons'
import { useHistory } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';

import { useStores } from '../hooks/use-stores';

const styles = {
  capitalize: {
    textTransform: 'capitalize',
  },
};

const CreateAccount = () => {
  const history = useHistory();
  const store = useStores();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordCheck, setPasswordCheck] = useState(null);
  const save = async () => {
    const result = await store.createAccount(email, password);
    history.push('/home');
  };
  return (
    <IonPage>
      <IonHeader>
      {store.deviceInfo.platform === 'web' ?
        <IonToolbar color="primary">
          <IonTitle size="large">WDW Tables</IonTitle>
        </IonToolbar> : null
      }
    </IonHeader>
      <IonContent
        scrollEvents={true}
        onIonScrollStart={() => {}}
        onIonScroll={() => {}}
        onIonScrollEnd={() => {}}
      >
        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            type="email"
            name="email"
            onIonChange={e => setEmail(e.detail.value)}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Password</IonLabel>
          <IonInput
            type="password"
            name="password"
            onIonChange={e => setPassword(e.detail.value)}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Re-enter Password</IonLabel>
          <IonInput
            type="password"
            name="passwordCheck"
            onIonChange={e => setPasswordCheck(e.detail.value)} 
          />
        </IonItem>
      </IonContent>
      <IonButton
        disabled={!(password && password === passwordCheck)}
        expand="full"
        color="primary"
        onClick={save}
      >
        Save
      </IonButton>
    </IonPage>
  );
};

export default observer(CreateAccount);