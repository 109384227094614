const timeout = 5000;
let lastTime = Date.now();
const callbacks = [];

setInterval(function() {
    const currentTime = Date.now();
    if (currentTime > (lastTime + timeout + 2000)) {
        callbacks.forEach(function (fn) {
            fn();
        });
    }
    lastTime = currentTime;
}, timeout);

const wakeEvent = function (fn) {
    callbacks.push(fn);
};

export default wakeEvent;