const firebaseConfig = {
  apiKey: "AIzaSyBkZa1JsB70r56mhBJuL8W1ScSYPzV0dpk",
  authDomain: "wdwtables.firebaseapp.com",
  databaseURL: "https://wdwtables.firebaseio.com",
  projectId: "wdwtables",
  storageBucket: "wdwtables.appspot.com",
  messagingSenderId: "786314296266",
  appId: "1:786314296266:web:ec6904c30f27bef38fad33"
};

export default firebaseConfig;
