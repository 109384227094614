export const ERRORS = {
  ERROR_CODE_ALREADY_IN_USE: 'auth/email-already-in-use',
  ERROR_CODE_ACCOUNT_EXISTS: 'auth/account-exists-with-different-credential',
  ERROR_CODE_WRONG_PASSWORD: 'auth/wrong-password',
  ERROR_CODE_NOT_FOUND: 'auth/user-not-found',
  ERROR_CODE_INVALID_EMAIL: 'auth/invalid-email',
};
export const ERROR_MSGS = {
  ERROR_MSG_INVALID_EMAIL: 'The email is invalid.',
  ERROR_MSG_WRONG_PASSWORD: 'The password is invalid.',
  ERROR_MSG_NOT_FOUND: 'The user is not found.',
  ERROR_MSG_ACCOUNT_EXISTS: `An account with this email address already exists. Please try logging into the account instead of creating a new account. Perhaps try logging in with either Google or Facebook.`,
};