import { types, getEnv } from 'mobx-state-tree';
import dayjs from 'dayjs';

import { DeviceToken, DeviceTokens } from './models/DeviceTokens';
import { ExtraSearch, ExtraSearches } from './models/ExtraSearches';
import { GlobalSearch, GlobalSearches } from './models/GlobalSearches';
import { Payment, Payments } from './models/Payments';
import { Restaurant, Restaurants } from './models/Restaurants';
import { SearchLog, SearchLogs } from './models/SearchLogs';
import { Setting, Settings } from './models/Settings';
import { Subscription, Subscriptions } from './models/Subscriptions';
import { User, Users } from './models/Users';
import { UserSearch, UserSearches } from './models/UserSearches';

const Db = types.model("Db", {
    searchFilter: types.optional(types.maybeNull(types.string), null),
    devicetokens: types.optional(DeviceTokens, {
        records: [],
    }),
    extrasearches: types.optional(ExtraSearches, {
      records: [],
    }),
    globalsearches: types.optional(GlobalSearches, {
      records: [],
    }),
    payments: types.optional(Payments, {
      records: [],
    }),
    restaurants: types.optional(Restaurants, {
      records: [],
    }),
    searchlogs: types.optional(SearchLogs, {
      records: [],
    }),
    settings: types.optional(Settings, {
      records: [],
    }),
    subscriptions: types.optional(Subscriptions, {
      records: [],
    }),
    usersearches: types.optional(UserSearches, {
      records: [],
    }),
    user: types.optional(User, {}),
  })
  .views(self => ({
    get activesearches() {
      const now = dayjs();
      let searches = self.usersearches.records
        .filter(s => dayjs(s.date).isAfter(now) && !s.deletedat)
        .sort((a, b) => (a.date < b.date) ? 1 : -1);
      if (self.searchFilter) {
        searches = searches.filter(r => r.restaurantdata.name.toLowerCase().search(self.searchFilter.toLowerCase()) !== -1);
      }
      return searches;
    },
    get expiredsearches() {
      const now = dayjs();
      let searches = self.usersearches.records
        .filter(s => dayjs(s.date).isBefore(now) && !s.deletedat)
        .sort((a, b) => (a.date < b.date) ? 1 : -1);
      if (self.searchFilter) {
        searches = searches.filter(r => r.restaurantdata.name.toLowerCase().search(self.searchFilter.toLowerCase()) !== -1);
      }
      return searches;
    },
    findRestaurant(search) {
      let retVal = self.restaurants.records
        .filter(r => !r.deletedat)
        .sort();
      if (search) {
        retVal = self.restaurants.records
          .filter(r => r.name.toLowerCase().search(search.toLowerCase()) !== -1 && !r.deletedat)
          .sort();
      }
      return retVal;
    },
    get listSubscriptions() {
      let retVal = self.subscriptions.records
        .filter(r => !r.deletedat)
        .sort((a, b) => (a.expires < b.expires) ? 1 : -1);;
      return retVal;
    },
    getSearchByUid(uid) {
      let retVal = self.usersearches.records.find(r => r.uid === uid);
      return retVal;
    },
    get lastrestaurantupdate() {
      let retVal;
      const lastUpdate = self.restaurants.records.reduce((a, b) => {
        return new Date(a.updatedat) > new Date(b.updatedat) ? a : b;
      });
      if (lastUpdate) {
        retVal = lastUpdate.updatedat.toISOString();
      }
      return retVal;
    }
  }))
  .actions(self => ({
    loadData(data) {
      if (data) {
        if (data.devices || data.devicetokens) {
          self.devicetokens.records.replace(data.devicetokens ? data.devicetokens.records : data.devices);
        }

        if (data.profile || data.users) {
          self.user = data.user ? data.user : data.profile;
        }

        if (data.payments) {
          self.payments.records.replace("records" in data.payments ? data.payments.records : data.payments);
        }

        if (data.subscriptions) {
          console.log(data.subscriptions);
          self.subscriptions.records.replace("records" in data.subscriptions ? data.subscriptions.records : data.subscriptions.subscriptions);
        }

        if (data.searches || data.usersearches) {
          self.usersearches.records.replace(data.searches ? data.searches : data.usersearches.records);
        }

        if (data.extrasearches) {
          self.extrasearches.records.replace("records" in data.extrasearches ? data.extrasearches.records : data.extrasearches);
        }

        if (data.globalSearches) {
          self.globalsearches.records.replace("records" in data.globalsearches ? data.globalsearches.records : data.globalsearches);
        }

        if (data.restaurants) {
          self.restaurants.records.replace("records" in data.restaurants ? data.restaurants.records : data.restaurants);
        }
      }
    },
    setSearchFilter(search) {
      self.searchFilter = search;
    },
    clearSearchFilter() {
      self.searchFilter = null;
    },
  })
);

export default Db;
