import React, { useState } from 'react';
import {
  IonAlert,
  IonItem,
  IonCard,
  IonContent,
  IonLabel,
  IonList,
  IonListHeader,
  IonIcon,
  IonAvatar,
  IonActionSheet,
  IonPage,
  IonFab,
  IonButton,
  IonFabButton,
  IonText,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import { useHistory } from "react-router-dom";
import { addIcons } from "ionicons";
import { card, checkmarkCircle, closeCircle, removeCircle, add, trash, time, exit } from 'ionicons/icons'
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import TimeAgo from 'react-timeago';
import spacetime from 'spacetime';

import { useStores } from '../hooks/use-stores';

dayjs.extend(utcPlugin);

const styles = {
  date: {
    fontSize: '0.95em',
    fontWeight: '500',
  },
  restaurant: {
    fontSize: '1.1em',
    fontWeight: '800',
  },
  last: {
    fontSize: '0.85em',
    fontWeight: '500',
  },
  times: {
    fontSize: '0.80em',
    fontWeight: '500',
    color: '#808080',
  }
};

const Status = ({ logs, expired }) => {
  let icon = closeCircle;
  let color = "danger";
  if (expired) {
    color = "warning";
    icon = removeCircle;
  } else if (logs.length && logs[0].foundseats) {
    icon = checkmarkCircle;
    color = "success";
  } else if (logs.length === 0) {
    icon = time;
    color = "medium";
  }

  return (
    <IonIcon icon={icon} size="large" color={color}/>
  );
};

const Times = ({ times }) => (
  <div style={styles.times}>
    {times.map((t, i) => (
      <span key={i}>{t.time}, </span>
    ))}
  </div>
);

const Search = ({ search, onClick, onDelete }) => (
  <IonItemSliding>
    <IonItem key={search.id} onClick={(e) => onClick(e, search, 'item')}>
      <IonAvatar slot="start">
        <Status logs={search.logs} expired={dayjs(search.date).isBefore(dayjs())} />
      </IonAvatar>
      <IonLabel>
        <div>
          <span style={styles.restaurant}>{search.restaurantdata.name}</span>
          &nbsp; &mdash; &nbsp;
          <span style={styles.date}>(Party of {search.partysize})</span>
        </div>
        <div
          style={styles.date}
        >
          {spacetime(search.date.toISOString(), 'UTC').goto('America/New_York').format('{day}, {month-short} {date-ordinal}, {year} {time}')}
        </div>
        {search.logs.length && search.logs[0].datesearched ? <div style={styles.last}><TimeAgo date={search.logs[0].datesearched.valueOf()} /></div> : null}
        {search.logs.length && search.logs[0].times && search.logs[0].times.length ? <Times times={search.logs[0].times} /> : null}
      </IonLabel>
      {search.logs.length && search.logs[0].times && search.logs[0].times.length ?
        <IonButton
          onClick={(e) => onClick(e, search, 'times')}
          slot="end"
        >
          <IonIcon icon={exit}/>
        </IonButton> :
        null
      }
    </IonItem>
    <IonItemOptions>
      <IonItemOption color="primary" onClick={(e) => onDelete(e, search)}>
        <IonIcon slot="end" icon={trash}></IonIcon>
        Delete
      </IonItemOption>
    </IonItemOptions>
  </IonItemSliding>
);

const TabHome = () => {
  const history = useHistory();
  const store = useStores();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [buttons, setButtons] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const handleClick = (e, search, type) => {
    e.stopPropagation();
    console.log('clicked',e, search, type);
    if (type === 'item') {
      history.push(`/edit/${search.id}`);
    } else {
      const times = search.logs[0].times.map(t => {
        const l = {
          text: t.time,
          handler: () => {
            store.openTimeUrl(t);
          }
        };
        return l;
      });
      times.push({
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });
      setButtons(times);
      setShowActionSheet(true);
    }
  };
  const handleDeleteClick = (e, search) => {
    setDeleteItem(search);
    setShowDeleteAlert(true);
  };
  const confirmDelete = (confirm) => {
    if (confirm) store.deleteSearch(deleteItem);
    setDeleteItem(null);
    setShowDeleteAlert(false);
  };
  const doRefresh = async (event) => {
    const { data } = await store.getUserSearches();
    data.forEach(search => {
      store.updateSearch(search);
    });
    event.detail.complete();
  }
  return (
    <IonPage>
      <IonHeader>
        {store.deviceInfo && store.deviceInfo.platform === 'web' ?
          <IonToolbar color="primary">
            <IonTitle size="large">WDW Tables</IonTitle>
          </IonToolbar> : null
        } 
      </IonHeader>
      <IonToolbar>
        <IonSearchbar
          placeholder="Filter Searches"
          debounce={500}
          value={store.db.searchFilter}
          onIonChange={(e) => store.db.setSearchFilter(e.currentTarget.value)}
          onIonClear={store.db.clearSearchFilter}	
        />
      </IonToolbar>
      <IonContent
        scrollEvents={true}
        onIonScrollStart={() => {}}
        onIonScroll={() => {}}
        onIonScrollEnd={() => {}}
      >
        {!store.currentSubscription ?
          <IonItem
            href="/subscriptions/add"
            color="warning"
          >
            <IonAvatar slot="start">
              <IonIcon icon={card} size="large" style={{ marginTop: 5 }} />
            </IonAvatar>
            <IonLabel color="dark">
              Upgrade to Standard
            </IonLabel>
          </IonItem> : null
        }
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonList>
          <IonListHeader>
            Current Dining Searches - {store.activesearches.length} / {store.totalsearches}
          </IonListHeader>
          {store.db.activesearches.map(s => <Search key={s.id} search={s} onClick={handleClick} onDelete={handleDeleteClick} />)}
        </IonList>
        <IonList>
          <IonListHeader>
            Expired Dining Searches
          </IonListHeader>
          {store.db.expiredsearches.map(s => <Search key={s.id} search={s} onClick={handleClick} onDelete={handleDeleteClick} />)}
        </IonList>
        <IonAlert
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          header={'Delete'}
          message={deleteItem ? `Do you want to delete ${deleteItem.restaurantdata.name}?` : null}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                confirmDelete(false);
              }
            },
            {
              text: 'Delete',
              handler: () => {
                confirmDelete(true);
              }
            }
          ]}
        />

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            color="secondary"
            href="/add"
            disabled={!store.availableSearches}
          >
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
        {buttons ? 
          <IonActionSheet
            isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            buttons={buttons}
          >
          </IonActionSheet> :
          null
        }
      </IonContent>
    </IonPage>
  );
};

export default observer(TabHome);